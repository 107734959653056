<template>
  <div class="goo-flash" :class="className">
    <slot />
  </div>
</template>

<script>
  export default {
    name: "GooFlash",
    props: {
      type: {
        type: String,
        default: "success"
      }
    },
    computed: {
      className () {
        return `goo-flash-${this.type}`;
      }
    }
  };
</script>

<style lang="scss">
  $flash-types: (
      "success": ("color": #155724, "bg": #d4edda, "border": #c3e6cb),
      "info":    ("color": #004085, "bg": #cce5ff, "border": #b8daff),
      "warning": ("color": #856404, "bg": #fff3cd, "border": #ffeeba),
      "error":   ("color": #721c24, "bg": #f8d7da, "border": #f5c6cb)
  );

  .goo-flash {
    padding: 16px;
    margin: 0 0 8px;
    border-radius: 4px;

    @each $type, $styles in $flash-types {
      &-#{$type} {
        color: map-get($styles, "color");
        background: map-get($styles, "bg");
        border: 2px solid map-get($styles, "border");
      }
    }
  }
</style>
