<template>
  <transition name="goo-modal-transition" appear>
    <goo-modal active class="goo-update-payment-method-dialog">
      <div class="goo-dialog goo-form-dialog">
        <goo-close-button @click="close" />
        <h3 class="heading-3">
          {{ title }}
        </h3>

        <form @submit.prevent="onSubmit">
          <fieldset>
            <GooFlash v-if="error" type="error">
              {{ error }}
            </GooFlash>
            <div class="goo-input-group is-inline">
              <div class="goo-control is-expanded">
                <label for="name" class="goo-label">Name on card</label>
                <input id="name" v-model="name" autocomplete="off" type="text" name="name" class="goo-input">
              </div>
            </div>

            <div class="goo-input-group is-inline">
              <div class="goo-control is-expanded">
                <label for="card-number-element" class="goo-label">Card Number</label>
                <div id="card-number-element" class="goo-input is-stripe-element" />
              </div>
            </div>

            <div class="goo-update-payment-method-dialog-footnote">
              <goo-icon name="lock" />
              <span>Your billing information will be encrypted and kept secure.</span>
            </div>
          </fieldset>

          <div class="goo-button-group is-equal-width">
            <goo-submit class="goo-button is-primary" :loading="saving" :disabled="!enableSubmitButton" loading-text="Saving">
              Continue
            </goo-submit>
          </div>
        </form>

        <hr class="goo-divider is-large">

        <div class="goo-update-payment-method-dialog-stripe-logo">
          <img src="./assets/powered-by-stripe.svg" alt="Powered by Stripe">
        </div>
      </div>
    </goo-modal>
  </transition>
</template>

<script>
  import { UPDATE_PAYMENT_METHOD } from "./mutations";
  import GooFlash from "@/goo2/components/goo-flash/GooFlash";

  export default {
    name: "GooUpdatePaymentMethod",
    components: { GooFlash },
    props: {
      hasPaymentMethod: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        name: "",
        saving: false,
        error: null,
        elements: null,
        completions: {
          card: false
        }
      };
    },
    computed: {
      title () {
        return this.hasPaymentMethod ? "Update card" : "Add card";
      },
      enableSubmitButton () {
        return this.completions.card && this.name.trim() !== "";
      }
    },
    mounted () {
      // eslint-disable-next-line
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      this.elements = this.stripe.elements();

      const style = {
        base: {
          color: "#3C4040",
          fontFamily: "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "18px",
          "::placeholder": {
            color: "#aab7c4"
          }
        }
      };

      const mapping = [
        { type: "card", target: "#card-number-element", params: { showIcon: true } }
      ];

      mapping.forEach((config) => {
        const element = this.elements.create(config.type, { style, ...config.params });
        element.mount(config.target);
        element.on("change", (e) => this.onElementChange(e));
      });
    },
    methods: {
      close () {
        this.$emit("cancel");
      },
      onElementChange (event) {
        // noinspection JSCheckFunctionSignatures
        this.$set(this.completions, event.elementType, event.complete);
      },
      onSubmit () {
        if (this.saving) {
          return false;
        }

        this.saving = true;
        this.error = null;

        // noinspection JSUnresolvedFunction
        this.stripe.createToken(this.elements.getElement("card"), {
          // Card holder name
          name: this.name
        }).then((result) => {
          return this.$apollo.mutate({
            mutation: UPDATE_PAYMENT_METHOD,
            variables: {
              input: {
                stripeToken: result.token.id
              }
            }
          });
        }).then(({ data: { result } }) => {
          if (result.ok) {
            this.$emit("updated");
          } else {
            this.error = result.error;
          }
        }).catch(() => {
          this.error = "An error has occurred, please try again.";
        }).finally(() => {
          this.saving = false;
        });
      }
    }
  };
</script>

<style lang="scss">
  .goo-update-payment-method-dialog {
    &-footnote{
      color: rgba(#000, 0.6);
      margin: 16px 0 0 0;
      display: flex;
      flex-direction: row;

      .goo-icon {
        flex-shrink: 0;
        margin: 0 8px;
      }
    }

    &-stripe-logo {
      text-align: center;
      line-height: 0;
    }
  }
</style>
