<template>
  <div class="goo-credit-card-logo">
    <img :src="logo" alt="" aria-hidden="true">
  </div>
</template>

<script>
  const JCB = require("./logos/JCB.svg");
  const Visa = require("./logos/VISA.svg");
  const Unknown = require("./logos/UNKNOWN.svg");
  const UnionPay = require("./logos/UNIONPAY.svg");
  const Discover = require("./logos/DISCOVER.svg");
  const MasterCard = require("./logos/MASTERCARD.svg");
  const DinersClub = require("./logos/DINERS_CLUB.svg");
  const AmericanExpress = require("./logos/AMERICAN_EXPRESS.svg");

  export default {
    name: "GooCreditCardLogo",
    props: {
      type: {
        type: String,
        default: null
      }
    },
    computed: {
      logo () {
        const name = this.type || "";

        switch (name.replace(/([^a-zA-Z0-9]+)/, "").toUpperCase()) {
        case "JCB":
          return JCB;
        case "VISA":
          return Visa;
        case "UNIONPAY":
          return UnionPay;
        case "DISCOVER":
          return Discover;
        case "MASTERCARD":
          return MasterCard;
        case "DINERSCLUB":
          return DinersClub;
        case "AMEX":
        case "AMERICANEXPRESS":
          return AmericanExpress;
        default:
          return Unknown;
        }
      }
    }
  };
</script>

<style lang="scss">
  .goo-credit-card-logo {
    width: 40px;
    display: inline-block;
    line-height: 0;
    img {
      user-select: none;
      width: 100%;
    }
  }
</style>
