import gql from "graphql-tag";

export const UPDATE_PAYMENT_METHOD = gql`
  mutation UpdatePaymentMethod($input: UpdatePaymentMethodInput!) {
    result: updatePaymentMethod(input: $input) {
      ok
      error
      paymentMethod {
        id
        brand
        lastDigits
        expMonth
        expYear
      }
    }
  }
`;
